import * as React from 'react';
import { getSeconds } from 'date-fns';
import { useSession } from 'next-auth/react';
import UAParser from 'ua-parser-js';
import type { Session } from 'next-auth';

interface InputAnalyticEvent {
  ServiceNetworkID: number;
  EventType: string;
  StartTime?: Date;
  EndTime?: Date;
  Duration?: number;
  CustomerID: number;
  CompanyID: number;
  EventTag: string;
  /**
   * if server, pass in req.headers['user-agent'], otherwise we'll default to navigator.userAgent
   * */
  userAgent?: string;
}

type Platform = 'Android' | 'iOS' | 'Web';
interface AnalyticEvent {
  ServiceNetworkID: number;
  EventType: string;
  StartTime: string;
  EndTime: string;
  Duration: number;
  CustomerID: number;
  CompanyID: number;
  Platform: Platform;
  PlatformVersion: string | null;
  EventTag: string;
  Device: '';
  DeviceID: '';
  VersionCode: string;
}

let parsed: UAParser.IOS | undefined;

function getOS(ua?: string): UAParser.IOS {
  if (parsed) {
    return parsed;
  }

  parsed = new UAParser(ua).getOS();
  return parsed;
}

function getPlatform(ua?: string): {
  name: Platform;
  version: string | null;
} {
  const os = getOS(ua);

  if (!os.name) {
    return { name: 'Web', version: null };
  }

  if (os.name.toLowerCase() === 'ios') {
    return { name: 'iOS', version: os.version ?? null };
  }

  if (os.name.toLowerCase() === 'android') {
    return { name: 'Android', version: os.version ?? null };
  }

  return { name: 'Web', version: null };
}

function webAnalytics({
  EventType,
  StartTime = new Date(),
  EndTime = new Date(),
  Duration = getSeconds(EndTime.getTime() - StartTime.getTime()),
  CustomerID,
  CompanyID,
  ServiceNetworkID,
  EventTag,
  userAgent,
}: InputAnalyticEvent) {
  if (process.env.ENV === 'staging') return undefined;
  const tagPrefix = 'billmgmt';

  const { name, version } = getPlatform(userAgent);

  const event: AnalyticEvent = {
    VersionCode: '1', //process.env.APP_VERSION.split('-')[0],
    CompanyID,
    ServiceNetworkID,
    CustomerID,
    Platform: name,
    PlatformVersion: version,
    Device: '',
    DeviceID: '',
    StartTime: StartTime.toISOString(),
    EndTime: EndTime.toISOString(),
    Duration,
    EventType,
    EventTag: EventTag !== '' ? `${tagPrefix}.${EventTag}` : tagPrefix,
  };

  const host =
    typeof window === 'undefined'
      ? process.env.ANALYTICS_URL
      : window.location.origin;
  const path =
    typeof window === 'undefined'
      ? '/v2/analytics/logevent'
      : '/api/v2/analytics/logevent';
  const url = new URL(path, host);
  url.searchParams.set('customerId', String(CustomerID));

  const body = JSON.stringify(event);

  // eslint-disable-next-line no-console
  // console.log(JSON.stringify(event, null, 2));

  try {
    return fetch(url.toString(), {
      method: 'post',
      body,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${process.env.NEXT_PUBLIC_ANALYTICS_AUTH}`,
      },
    });
  } catch (error: unknown) {
    console.error(error);
    return undefined;
  }
}

type NarrowAnalyticEvent = Pick<
  InputAnalyticEvent,
  'Duration' | 'EndTime' | 'EventTag' | 'EventType' | 'StartTime'
>;

function useWebAnalytics() {
  const { data: session } = useSession();

  const sessionRef = React.useRef<Session | null>(null);

  React.useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  return React.useCallback((event: NarrowAnalyticEvent) => {
    if (!sessionRef.current || !sessionRef.current.user.accountNumber) {
      return undefined;
    }
    return webAnalytics({
      CompanyID: sessionRef.current.user.companyId,
      CustomerID: sessionRef.current.user.customerId,
      EventTag: event.EventTag,
      EventType: event.EventType,
      ServiceNetworkID: sessionRef.current.user.serviceNetworkId,
      Duration: event.Duration,
      EndTime: event.EndTime,
      StartTime: event.StartTime,
    });
  }, []);
}

export { webAnalytics, useWebAnalytics };
