export const componentsJson = {

    "BillManagement": {
        "BillEstimateText": {
            "analyzeText": "Analyze My Bill shows the total current charges for your energy use and may not reflect the amount due or actual dates in your next billing period. To see your account balance",
            "analyzeText2": "or check your latest billing statement."
        },
        "BillProjectionHeader": {
            "headerText": "Your next bill estimate is trending",
            "headerText2": "higher than your current bill.",
            "headerText3": "Projected Bill Estimate",
            "headerText4":"Got it",
            "headerText5": "Your Projected Bill estimate is pending and should be ready 24-48 hrs.",
            "headerText6":"Learn More",
            "headerText7":"My Current Bill",
            "headerText8":"Bill Pending",
            "headerText9": "No Data",
        },
        "Carousel": {
            "dropdownText": "bill a year ago",
            "dropdownText2":"previous bill",
            "dropdownText3":"This is about",
            "dropdownText4":"lower",
            "dropdownText5":"than your",
            "dropdownText6":"This is about the same as your",
            "dropdownText7":"higher",
            "dropdownText11":"CCF higher",
            "dropdownText10":"CCF lower",
            "dropdownText9":"kWh higher",
            "dropdownText8":"kWh lower",

        },
        "GatheringUsage": {
            "usageText": "Welcome! Just a moment while we set up your experience.",
            "usageText2":"This may take up to 30 seconds the first time you visit.",
            "usageText3": [
                'Gathering bill history...',
                'Analyzing next bill...',
                'Collecting bill cycle data...',
                'Checking data accuracy...',
                'Identifying bill factors...',
            ],
            "disclaimer": "If you are a new DTE customer, this tool will only be available after you receive your first bill. If you’ve already received your bill and are encountering technical issues, please check back again tomorrow. We apologize for the inconvenience.",
        },
        "HomeProfile": {
            homeProfileText: "Pending",
            homeProfileText1: "Lighting",
            homeProfileText2: "Laundry",
            homeProfileText4: "Kitchen",
            homeProfileText7: "Show my breakdown estimate",
            homeProfileText8: "Next",
            homeProfileText11: "Other",
            homeProfileText17: "Discover what is contributing to the cost of your bill!",
            homeProfileText16: "Let us know the following so that we can build an estimate of how your usage breaks down.",
            homeProfileText15: "What kind of home do you live in?",
            homeProfileText13: "Multi-Unit",
            homeProfileText14: "Detached Single-Family",
            homeProfileText12: "Attached Single-Family",
            homeProfileText9: "How many occupants live in your home?",
            homeProfileText10: "How many square feet is your home?",
            homeProfileText5: "Heating & Cooling",
            homeProfileText6: "Your data is being compiled and may take up to 24 hours to categorize",
        },
        "IntroTour":{
            introTourText: "Take A Tour",
            introTourText2: "The Bill Analyzer Tool reflects the total current charges for each billing period. The tool may not reflect your invoice amount. To view your current billing statement,",
            introTourText3: "click here",
            introTourTextWelcome: {
                title: 'Welcome to the Bill Analyzer Tool!',
                text: 'Here you will find useful features to understand and manage your bill. Let’s explore the tool.',
            },
            introTourTextNavBillHistory: {
                title: 'Bill History',
                text: 'Visit Bill History to compare your monthly bills to determine the impact of weather, bill period length, top bill contributors, and yearly costs.',
            },
            introTourTextNavBillSimulator: {
                title: 'Bill Simulator',
                text: 'Use the simulator to explore appliance upgrades, electric vehicle charging, and rate options for potential cost savings.',
            },
            introTourTextNavEnergy: {
                title: 'Energy Usage Data',
                text: 'View and compare your daily and hourly usage to assist with managing energy costs.',
            },
            introTourTextCurrent: {
                title: 'My Current Bill',
                text: 'Your most recent billing period based on your total current charges.',
            },
            introTourTextInfo: {
                title: 'Information',
            },
            introTourTextUsage: {
                title: 'Total Cost/Usage',
                text: 'Select how you want to display your energy use between total cost or total usage on the bar charts.',
            },
            introTourTextBarChart:{
                title: 'Bar Chart',
                text: 'Compare your current, previous, and projected bill. The Projected Bill is a forecast based on your usage and will update daily to give you insights on your future bill costs.',
            },
            introTourTextBillContributors:{
                title: 'Bill Contributors',
                text: 'See your estimated costs from general appliances and other categories that contribute to your energy bill.',
            },
            introTourTextBillFactors: {
                title: 'Bill Factors',
                text: 'Curious about the factors that can impact your bill? Learn how weather and bill period length can drive higher or lower energy costs.',
            },
            introTourTextWaysToSave: {
                title: 'Ways To Save',
                text: 'Save with energy efficiency programs and tips for your home.',
            },
            introTourTextApplication: {
                title: 'Application Overview',
                text: 'Check out these features to get a detailed overview of your bill.',
            },
            introTourTextSupport: {
                title: "You're All Set!",
                text: 'If you ever want to see the walkthrough, you can access it here.',
            }


        },
        "MissingSites": {
            missingSitesText: "We were unable to locate an active account associated with this address.",
            missingSitesText2: "Please close this window.",
            missingSitesText3: "Status code:"
        },
        "PaymentPlan": {
            paymentPlanText: "My Payment Plan",
            paymentPlanText2: "You are currently enrolled in",
            paymentPlanText3: "Current Payment Plan Amount:",
            paymentPlanText4: "No Data.",
            paymentPlanText5: {
                BWB: 'BudgetWise Billing®',
                LSP: 'Low-Income Self-Sufficiency Plan',
                SPP: 'Shutoff Protection Plan',
                WPP: 'Winter Protection Plan',
            },
        },
        "RateChangeBanner": {
            rateChangeBannerText: "Your Rate Change is pending to",
            rateChangeBannerText2: "This rate will take effect at the start of your next billing cycle on or around",
            rateChangeBannerText3: "If you no longer want to switch, please go to",
            rateChangeBannerText4: "Bill Simulator - Explore Rate Types",
            rateChangeBannerText5: "and select another rate before your next billing cycle.",
        },
        "DTENav": {
            dteNavText: "Open navigation menu",
            dteNavText2: "Analyze My Bill",
            dteNavText3: "for:",
            dteNavText4: "Sign Out",
            dteNavText5: "Take A Tour",
            dteNavText6: {
                label: "Visit DTEEnergy.com",
                value: process.env.NEXT_PUBLIC_DOMAIN
            },
            dteNavText7: "Sign Out",
            dteNavText8: "Close navigation menu",
            dteNavText9: "Change Site",
            dteNavText10: {
                label: "Twitter",
                value: "https://www.twitter.com/dte_energy"
            },
            dteNavText11: {
                label: "Facebook",
                value: "https://www.facebook.com/dteenergy"
            },
            dteNavText12: {
                label: "LinkedIn",
                value: "https://www.linkedin.com/company/dte-energy"
            },
            dteNavText13: {
                label: "YouTube",
                value: "https://www.youtube.com/user/DTEEnergyCompany"
            },
            dteNavText14: {
                label: "Terms & Conditions",
                value: "/wps/wcm/connect/dte-web/quicklinks/footer/terms-and-conditions"
            },
            dteNavText15: {
                label: "Online Privacy Policy",
                value: "/wps/wcm/connect/dte-web/quicklinks/footer/01+online+privacy+policy"
            },
            dteNavText16: {
                label: "Contact Us",
                value: "/wps/wcm/connect/dte-web/quicklinks/footer/03+contact+us"
            },
            dteNavText17: "DTE Energy. All rights reserved.",
            dteNavText18: "Powered by",
        },
        "SupportCenter": {
            supportsText: {
                label: "Report a problem with your projection",
                value: "https://powerley.typeform.com/to/oAzFCJMi#id=",
                tag: "reportaproblem"
            },
            supportsText2: "It's important to us that your data is accurate. Notice anything that seems unusual? Let’s make sure it's right."
        },
        "TemporarilyUnavailable":{
            temText: "Your energy data is temporarily unavailable for this address, check back once you've received your bill.",
            temText2: "Please close this window.",
        },
        "TrySimulator":{
            trySimulatorText: "Check out the Bill Simulator",
            trySimulatorText2: "Explore how changes you make in your home and energy habits could affect your energy bills.",
            trySimulatorText3: "Launch Bill Simulator"
        },
        "UnknownPremise": {
            unknownPremiseText: "We were unable to locate an active account associated with this address",
            unknownPremiseText2: "Navigate to the “Select Site” option to select another address."
        },
        "BillBreakdown": {
            billBreakdownText: "Bill Factors Video",
            billBreakdownText2: "Learn more about the key factors that impact your bill.",
            billBreakdownText3: {
                label: "Watch Video",
                value: "https://www.youtube.com/embed/RiqOnVwAA3k"
            },
        },
        "BillHistory": {
            billHistoryText: "Bill History",
            billHistoryText2: "Visit Bill History to compare your monthly bills to determine the impact of weather, bill period length, top bill contributors, and yearly costs.",
            billHistoryText3: "View Bill History"
        },
        "BillSimulator": {
            billSimulatorText: "Bill Simulator",
            billSimulatorText2: "Use the simulator to explore appliance upgrades, electric vehicle charging, and rate options for potential cost savings.",
            billSimulatorText3: "Launch Bill Simulator",
        },
        "DoYouSmellGas": {
            doYouSmellGasText: "Do you smell gas?",
            doYouSmellGasText2: "Leave the area immediately by going outside, then call 9-1-1 and (800)-947-5000. Every homeowner needs to be able to detect the signs of a gas leak.",
        },
        "EnergyUsage": {
            energyUsageText: "Energy Usage Data",
            energyUsageText2: "View and compare your daily and hourly usage to help with managing energy costs.",
            energyUsageText3: "View Data"
        },
        "ReturnToDTE": {
            returnToDteText: "We were unable to locate an account associated with this address.",
            returnToDteText2: "Please",
            returnToDteText3: "click here",
            returnToDteText4: "to go back to DTEEnergy.com",
        },
        "FurnaceBoost": {
            furnaceBoostText: "Give your furnace a boost!",
            furnaceBoostText2: "Keep your furnace working at peak efficiency by regularly changing the air filters, cleaning the vents, and getting routine tune-ups. If it's older consider a modern, high-efficiency model.",
            furnaceBoostText3: {
                label: "Save with Rebates",
                value: "https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/save-money-energy/residential/rebates-and-offers/furnace-and-boilers"
            }
        },
        "LeakyWindows": {
            leakyWindowsText: "Save up to 20% by sealing leaky windows and doors!",
            leakyWindowsText2: "Sealing air leaks and adding insulation makes your home more comfortable and energy efficient.",
            leakyWindowsText3: {
                label: "Browse Smart Device Shop",
                value: "https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/save-money-energy/residential/rebates-and-offers/insulation-and-windows?utm_campaign=dte-res-inwin&utm_medium=search-responsive&utm_source=google&gclid=Cj0KCQjwsrWZBhC4ARIsAGGUJupolJlZXo1OrKCEkuI261ECEeQ6-hs-Wv8yGVhUELpCgM2S3VRlaGcaAq_IEALw_wcB#insulation"
            },
        },
        "LightUpgrade": {
            lightUpgradeText: "Upgrade your lightbulbs to LED – or even further with smart bulbs!",
            lightUpgradeText2: "Switching from incandescent to LED light bulbs is the easiest way to reduce your monthly cost.",
            lightUpgradeText3: {
                label: "Browse Smart Device Shop",
                value: "https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/save-money-energy/residential/rebates-and-offers/lighting?_ga=2.174523234.1384745245.1663874678-5606030.1635170343&_gl=1*16c5w1d*_ga*NTYwNjAzMC4xNjM1MTcwMzQz*_ga_J2R5W9DWE4*MTY2Mzk2NjUxMy41MS4xLjE2NjM5NjY1MzcuMC4wLjA"
            }
        },
        "Upsell1A": {
            upsell1AText: "Save up to $100 a year on your energy bill.",
            upsell1AText2: "Monitor your energy use and costs live with the DTE Insight app and Energy Bridge hub. See how and when your home uses energy.",
            upsell1AText3: {
                label: "The more you know, the more you can save.",
                value: "https://smart.link/qo23r5baoi7jp?campaign=21_BillMgmt_1A"
            },
            upsellAText4: "Photo of a hand holding a phone showing real-time energy usage for a day. In the background is a DTE paper bill."
        },
        "Upsell1B": {
            upsell1BText: "Save up to $100 a year on your energy bill.",
            upsell1BText2: "Monitor your energy use and costs live with the DTE Insight app and Energy Bridge hub. See how and when your home uses energy.",
            upsell1BText3: {
                label: "The more you know, the more you can save.",
                value: "https://smart.link/2ycdruqgooack?campaign=21_BillMgmt_1B",
            },
            upsell1BText4: "Animation of a person sitting at desk using a computer. There is an Energy Bridge on the desk and a lamp next to the desk, with a floating white bubble that displays an example of current cost per hour and energy usage in real time as the person works on their computer and the lamp turns on and off."
        },
        "Upsell2A":{
            upsell2AText: "Find out which appliances are costing you the most.",
            upsell2AText2: "With the DTE Insight app and Energy Bridge hub, you'll see exactly what is driving your bill higher.",
            upsell2AText3: {
                label:"Take control of your energy.",
                value: "https://smart.link/fz7i8qfne4wny?campaign=21_BillMgmt_2A"
            },
            upsell2AText4: "Photo of a hand holding a phone showing real-time energy usage for a day. In the background is a DTE paper bill."
        },
        "Upsell2B": {
            upsell2BText: "Find out which appliances are costing you the most.",
            upsell2BText2: "With the DTE Insight app and Energy Bridge hub, you'll see exactly what is driving your bill higher.",
            upsell2BText3: {
                label:"Take control of your energy.",
                value: "https://smart.link/25u6engue03gd?campaign=21_BillMgmt_2B"
            },
            upsell2BText4: "Animation of a person standing in the foreground and tapping their phone. In the background is a kitchen. As the person taps their phone, different kitchen appliances are highlighted in the background with a speech bubble showing its cost per year."
        },
        "Rebates":{
            rebatesText: "Photo of a hand holding a phone showing real-time energy usage for a day. In the background is a DTE paper bill.",
            rebatesText2: "Start saving with rebates and programs from DTE",
            rebatesText3: {
                label: "View Available Programs",
                value: "https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/save-energy/residential/start+saving/ways+to+save/"
            },
        },
        "Shared": {
            sharedText: "Download now",
            sharedText2: "Download on the App Store",
            sharedText3: "Get it on Google Play",
        }
    }
}
