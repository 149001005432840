import * as React from 'react';

import { useApp } from './context';

function HeaderSiteSwitcher() {
  const { customerSites, customerSiteId } = useApp();
  const ref = React.useRef<HTMLFormElement>(null);

  return customerSites.length > 1 ? (
    <form method="post" action="/api/change-site" ref={ref}>
      <select
        className="select-arrow-white border-none bg-header text-lg font-semibold leading-[30px] text-white"
        defaultValue={customerSiteId}
        name="customerSiteId"
        onChange={() => {
          if (!ref.current) {
            return null;
          }

          return ref.current.submit();
        }}
      >
        {customerSites.map(site => (
          <option key={site.customerSiteId} value={site.customerSiteId}>
            {site.address}
          </option>
        ))}
      </select>
    </form>
  ) : (
    <span className="ml-3 text-lg font-semibold leading-[30px] text-white">
      {customerSites[0]?.address}
    </span>
  );
}

const SiteSwitcher: React.VFC = () => {
  const { customerSites, customerSiteId } = useApp();
  const ref = React.useRef<HTMLFormElement>(null);

  if (customerSites.length < 2) {
    return (
      <div className="hidden lg:block">
        <span className="flex items-center text-sm">
          <span className="font-semibold text-black text-opacity-90">
            Current Site:{' '}
            <span className="border-none bg-transparent font-semibold">
              {customerSites[0].address}
            </span>
          </span>
        </span>
      </div>
    );
  }

  return (
    <form
      method="post"
      action="/api/change-site"
      className="hidden lg:block"
      ref={ref}
    >
      <label className="flex items-center text-sm">
        <span className="font-semibold text-black text-opacity-90">
          Select Site:{' '}
        </span>
        <select
          className="border-none bg-transparent font-semibold"
          defaultValue={customerSiteId}
          name="customerSiteId"
          onChange={() => {
            if (!ref.current) {
              return null;
            }

            return ref.current.submit();
          }}
        >
          {customerSites.map(site => (
            <option key={site.customerSiteId} value={site.customerSiteId}>
              {site.address}
            </option>
          ))}
        </select>
      </label>
    </form>
  );
};

export { HeaderSiteSwitcher, SiteSwitcher };
